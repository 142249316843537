//test with themes
// $theme-dark: '.theme-dark';
// $theme-light: '.theme-light';

:root {
  --primaryLight-color:#fde0c1;
  --primary-color: #f68e1e;
  --secondaryLight-color: #c3e1ff;
  --secondaryLighter-color: #0074e5;
  --secondary-color: #004990;
  --neutralLightLight-color:#f4f4f4;
  --neutralLight-color: #cacaca;
  --neutralLighter-color:#757575;
  --neutral-color: #424242; //main font color
  --neutralDarker-color: #313131;
  --basic-color: #fff;
  --basicDarker-color: #F2F7FB;
  --basicDarkDarker-color: #d8e7f3;

}
[data-theme="dark"] {
  --primary-color: #9A97F3;
  --secondary-color: #818cab;
  --font-color: #e1e1ff;
  --bg-color: #161625;
}



// $themes: (
//   light: (
//     primary: #ff6754,
//     backgroundColor: #FFFFFF,
//     textColor: #408bbd,
//     buttonTextColor: #408bbd,
//     buttonTextTransform: none,
//     buttonTextHoverColor: #61b0e7,
//     buttonColor: #fff,
//     buttonBorder: 2px solid #fff,
//   ),
//   dark: (
//     backgroundColor: #222,
//     textColor: #ddd,
//     buttonTextColor: #aaa,
//     buttonTextTransform: uppercase,
//     buttonTextHoverColor: #ddd,
//     buttonColor: #333,
//     buttonBorder: 1px solid #aaa,
//   ),
// );

// @mixin themify($themes) {
//   @each $theme, $map in $themes {
//     .theme-#{$theme} & {
//       $theme-map: () !global;
//       @each $key, $submap in $map {
//         $value: map-get(map-get($themes, $theme), '#{$key}');
//         $theme-map: map-merge($theme-map, ($key: $value)) !global;
//       }
//       @content;
//       $theme-map: null !global;
//     }
//   }
// }

// @function themed($key) {
//   @return map-get($theme-map, $key);
// }

