@import '../base/themes';


thead {
  display: table-header-group;
}

//for repeating header on each page when printing
.tableOverflow{
  //height:100vh;
  overflow-y: hidden;
  height:100%;
  @media(min-width: $breakpoint-large) {
    height:calc(100vh - 250px);
    overflow-y: auto;
  }
  @media print {
    height: 100%;
  }
  //smartphones / touch

  
}
.ticketTable {
  border-collapse: collapse;
  margin-top: $m-size;
  width: 100%;
  word-break: break-word;
  //overflow: auto;
 // height: calc(100vh - $header-height); //TBD fix calc 
 //height: 100vh;
  //display:block;
  @media print {
    //table-layout: fixed;
  }
  &__actionRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    margin: 0 0 $s-size 0;
    padding: 0;

    li {
      align-items: center;
      cursor: pointer;
      display: flex;
      margin-left: $m-size;
      padding: $m-size $m-size 0 $m-size;
    }

    @media print {
      display: none;
    }
  }

  &__wrapper {
    background-color: var(--basic-color);
    border-radius: $border-radius;
    margin: 0 $m-size;
    padding: $s-size $m-size;
    .searchWrapper {
      @media print {
        display: none
      }
    }
    .searchContainer {
      vertical-align: middle;
      display: table-cell;


      input {
        border: solid;
        border-width: 1px;
        border-color: var(--neutralLight-color);
        border-radius: $border-radius;
        padding: $xs-size;
        padding-right: 30px; //match span margin-left
        vertical-align: bottom;
      }

      .clearSearch {
        margin-left: -30px; //iconsize 24px + 6px padding
        cursor: pointer;
      }

    }
    .sortWrapper { //search on small&medium screensize
      width:100%;
      @media(min-width: $breakpoint-medium) {
        width:50%
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
  }

  &__col1 {
    //ticket list when ticket is open
    display: none;

    @media(min-width: $breakpoint-large) {
      display: block;
      width: 60%
    }

    @media print {
      width: 100%
    }
  }

  &__col2 {
    //ticket
   // max-height: 100vh;
    overflow-y: auto;
    padding-top:$m-size;
    width: 100%;

    @media(min-width: $breakpoint-large) {
      padding-top: calc(#{$m-size} + #{$header-height});
      width: 40%
    }

    @media print {
      display: none
    }
  }

  th {
    text-align: left;
    &:first-of-type{
      width: 1%;
      white-space: nowrap;
    }
  }

  &__headerCell {
    cursor: pointer;
    padding: $m-size;

    &.selectedSort {
      color: var(--primary-color)
    }
  }

  &__row {
    cursor: pointer;
    
    &:hover {
      background-color: var(--basicDarker-color);
    }
 

    &.selected {
      background-color: var(--basicDarkDarker-color);

      @media print {
        background-color: none;
      }
    }

    @media print {
      page-break-inside: avoid;
      page-break-after: auto;
      padding: 0mm
    }

    &.descRow td {
      @media print {
        margin: 0mm;
        padding-bottom: 3mm;
        padding-top: 0mm;
      }
    }

    tr {
      display: table-cell;
      @media(min-width: $breakpoint-large) {
        display: table-row;
      }
      @media print {
        display: table-row;
      }
  
    }

    td {
      padding: $s-size $m-size;
      display: inline;

      @media(min-width: $breakpoint-large) {
        display: table-cell;
      }
      @media print {
        margin: 0mm;
        padding: 0mm;
        display: table-cell;
   
      }
      .descTitle {
        display: block;
        margin-bottom: 0;
        margin-top: 0;
        text-decoration: underline;
        @media print {
          padding: 0mm;
        }
        .desc {
          overflow-wrap: break-word;
          word-wrap: break-word;
        }
      }
    }

    .mainTd {
      border-top: 1px solid #dee2e6;
      @media print {
        margin: 0mm;
        padding: 0mm;
      }
    }
  }
  @media print {
    thead {
      //display: table-header-group;
    }
  }
  &__smallRow {
    border-bottom: solid 1px var(--neutralLight-color);
    &:first-of-type{
      border-top: solid 1px var(--neutralLight-color);
    }
    cursor:pointer;
    td {
      &:last-of-type {
        padding-bottom: $s-size;
        margin-bottom: $s-size;
      }
    }
    .singleLine {
      margin: 0;
      &.flex{
        display:flex;
        justify-content: space-between;
      }

      &.title {
        font-weight: 400;
        display: flex;
        align-items: center;
        span {margin-left: $s-size}
      }
    }
    .desc{
      background-color: var(--basicDarker-color);
    }
    .test{
      text-decoration: none;
    }
  }
}



//THEME TEST
// .app-container {
//   @include themify($themes) {
//     color: themed('textColor');  
//     background-color: themed('primary');  
//   }

//   .title {
//     font-family: sans-serif;
//     font-weight: lighter;
//   }

//   .button {
//     cursor: pointer;
//     border-radius: 5px;
//     padding: 15px 32px;
//     display: inline-block;
//     transition: color 0.1s, border-color 0.1s, background-color 0.1s;
//     @include themify($themes) {
//       border: themed('buttonBorder');
//       color: themed('buttonTextColor'); 
//       border-color: themed('buttonTextColor');
//       background-color: themed('primary');
//       text-transform: themed('buttonTextTransform');
//       &:hover {
//         color: themed('buttonTextHoverColor'); 
//         border-color: themed('buttonTextHoverColor');
//         background-color: themed('buttonHoverColor');
//       }
//     }

//   } 
// }