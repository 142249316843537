.header {
  @media print {
    display: none
  }
}

.navbar {
  align-items: center;
  background: var(--basic-color);
  box-shadow: $box-shadow;
  display: flex;
  //flex-direction: column;
  justify-content: space-between;
  height: $header-height;
  position: fixed;
  top: 0;
  z-index: 1028;
  width: 100%;

  @media(min-width: $breakpoint-medium) {
    flex-direction: row;
    justify-content: space-between;
  }

  &__logo {
    align-items: center;
    display: flex;
    padding:$m-size;
    img{
      height: 40px;
      max-height: 40px; 
  }}
  &__kebab{
    padding: 0 $m-size;
  }
  &__container {
    align-items: center;
    display: flex;

    .navItem {
      align-items:center;
      display:flex;
      padding: 0 $m-size;
      span{
        margin-left: $xxs-size;
      }
      &:first-of-type {
        padding-left: 0;
        padding-right:0;
      }
      &:nth-of-type(2){
        //padding-left: 0;
        margin-left: $m-size;
      }
    }
  }
}
.burgerMenu {
  display: flex;
  flex-direction: column;
  justify-content:center;
  background: var(--primary-color);
  height: 100vh;
  text-align: center;
  padding: $m-size;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  
  width:100%;
  &.translNo{
    transform: translateX(0)
  }
  &.translFull{
    transform: translateX(-100%);
  }
  @media(min-width: $breakpoint-large) {
    width:auto; //TBD
  }
  a {
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: var(--neutral-color);
    text-decoration: none;
    text-align: center;
    transition: color 0.3s linear;
    @media(min-width: $breakpoint-large) {
      font-size: 2rem;
     // text-align: left;
    }
  }
  &__icon {
    color:var(--neutral-color);
    height: 24px;
    stroke-width: 1;
    width:24px;
    display:inline-block;
    vertical-align:middle;
  }
}

.logoutWrapper{
  display:flex;

}