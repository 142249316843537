.closeTicket{
  cursor:pointer;
  padding-right: $m-size;
  text-align:right;
}
.ticketContainer {
  //padding: $m-size;
  
  @media(min-width: $breakpoint-large) {
    border: solid 1px var(--neutralLight-color);
    margin: 0 $m-size;
    padding:$l-size;
    //width:95%;
  }
  .completeTicket{

    margin-bottom: $m-size;
    &__button{
      cursor: pointer;
      display: table;
      padding: $xs-size $m-size;
      border-radius: $border-radius;
      border: solid 2px var(--primary-color);
     span{
      display:table-cell;
      vertical-align: middle;
     }
     svg{
      display:table-cell;
      vertical-align: middle;
     }
    }
  }

  fieldset {
    border:none;
    padding:0;
    @media(min-width: $breakpoint-large) {
      border: solid 1px var(--neutralLight-color);
      padding: 0.35em 0.75em 0.625em;
    }
  }
  legend {
    color: var(--neutralLighter-color);
    font-weight:600;
    padding: 0 $xs-size;
    @media(min-width: $breakpoint-large) {
      font-weight: 300;
    } 
  }
  .displayGroup{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &:first-of-type{
      padding-top: $m-size;
      @media(min-width: $breakpoint-large) {
        //padding-top: $xl-size;
      }
    }
    @media(min-width: $breakpoint-large) {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: $m-size;
    }
    &__item{
      flex-grow: 1;
      flex-basis: 0;
      &:nth-child(2){
        @media(min-width: $breakpoint-large) {
          margin-left: $m-size;
        }
      }
    }
  }
  .itemData{
    border-bottom: solid 1px var(--neutralLight-color);
    margin-top: 0;
    &.descriptionArea {
      background: var(--basic-color);
      border:none;
      border-bottom: solid 1px var(--neutralLight-color);
      color: var(--neutral-color);
      display:block;
      overflow-wrap: break-word;
      resize: none;
      width:100%;
      word-wrap: break-word;
    }
  }
  .itemLabel{
    color: var(--neutralLighter-color)
  }
}