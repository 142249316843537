
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");



h1, h2, h3, h4 {
  font: 'Montserrat', sans-serif
}
h3 {
  font-weight: 700
}

// font size
$font-standard: 1.6rem; //16px
$font-size-xsmall: 1.2rem;
// $font-size-small: 1.4rem;
// $font-size-large: 1.8rem;
// $font-size-xlarge: 2.1rem;
// $font-size-jumbo: 3rem;

//Spacing
$xxs-size:0.5rem;
$xs-size: 0.8rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem; 

//ui
$box-shadow: 0 2px 4px 0 rgba(43,43,43,0.1);
$header-height: 70px; //TBD fix responsive
$border-radius: 5px;


$header-height-large-screens: 6rem; //TEST

$header-height-medium-screens: 13rem; //also for smaller screens

$breakpoint-large-value: 1150; //large
$breakpoint-medium-value: 770; //medium
$breakpoint-small-value: 550; //small
$breakpoint-large: $breakpoint-large-value +0px; 
$breakpoint-medium: $breakpoint-medium-value +0px; 
$breakpoint-small: $breakpoint-small-value +0px;

// $breakpoint-large: 1150px; //large
// $breakpoint-medium: 770px; //medium
// $breakpoint-small: 550px; //small

//Export variables to JavaScript using Webpack
//https://www.bluematador.com/blog/how-to-share-variables-between-js-and-sass
:export {
  breakpoint-large-value: $breakpoint-large-value; 
  breakpoint-medium-value: $breakpoint-medium-value; 
  breakpoint-small-value: $breakpoint-small-value; 
  breakpoint-large: $breakpoint-large; 
  breakpoint-medium: $breakpoint-medium; 
  breakpoint-small: $breakpoint-small; 
  space-medium: ($m-size / 1rem)*10; //strip unit
  space-xl: ($xl-size / 1rem)*10; //strip unit
  header-height: $header-height / 1px; //strip unit
}
