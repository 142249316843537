.standardSquareIcon {
  color:var(--neutralLighter-color);
  height: 24px;
  stroke-width: 1;
  width:24px;
&--primary {
  color: var(--primary-color)
}
&--secondary {
  color: var(--secondaryLighter-color)
}
&--notification {
  color: var(--primary-color)
}
}

.modalIcon{
  color:var(--neutralLighter-color);
  height: 40px;
  stroke-width: 1;
  width:40px;
}
