
.privacyModal {
  align-self: flex-start;
  border: solid 1px var(--neutralLighter-color);
  margin-top: $l-size;
  max-height: 90vh;
  max-width: 90vw;
  overflow-y: auto;
  background-color: var(--basic-color);
  z-index:1200;
  @media(min-width: $breakpoint-large) {

    max-width: 50vw;
  }
  &__overlay{
    display:flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index:1199;
  }
  &__body{
    padding: $l-size;
    text-align: left;
  }
  &__icon {
    text-align: center;
  }
  &__title {
    color:var(--secondary-color);
    text-align:center;
  }
  &__link{
    color: var(--secondary-color);
    display:block;
    margin-bottom:$l-size;
    margin-top: $l-size;
    text-align:center;
  }
  &__confirm {
    padding: $m-size;
    cursor:pointer;
    color: var(--basic-color);
    background: var(--secondary-color);
    border-radius: $border-radius;
    border:none;
    font-weight: 600;
    text-align:center;
    &:hover{
      background-color:var(--secondaryLighter-color)
    }
  }
  &__btnContainer{
    text-align: center;
  }
}

.menuList {
  padding-left:0;
  &__option {
    list-style-type:none;
    cursor: pointer;
    &:hover {
      background: var(--secondaryLight-color);
    }
  }
}

.filterModal {
  position:absolute;
  background-color: var(--basic-color);
  border: solid 1px var(--neutralLighter-color);
  //border-color: var(--neutralLight);
}