.commentContainer{
  margin-top: $l-size;
}

.commentList {
  padding-left:0;
}
.commentItem {
  display:flex;
  list-style: none;
  margin-bottom: $l-size;
  .commentIcon{
    padding-right: $s-size;
  }

  .content{
    background-color: var(--primaryLight-color);
    border-radius:$border-radius;
    padding: $m-size;
    word-wrap: break-word;
    &__line{
      margin:0;
    }
  }
  &.reverse {
    flex-direction: row-reverse;
    text-align: right;
    .commentIcon{
      padding-left: $s-size;
      padding-right:0;
    }
    .commentInfo{
      text-align: right;
    }
    .content {
      background-color: var(--basicDarker-color);
      text-align:right;
      &__line{
        margin:0;
      }
    }
  }

  .commentInfo{
    margin-top:0;
    .authorInfo{
      display:block;
      font-weight:400;
    }
    .date {
      display:block;
      font-size: $font-size-xsmall;
      line-height:$s-size;
      margin-bottom: 2px;
    }
  }

}  
.commentAdd{
  &__row {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: $m-size;
    .textarea {
      flex: 1;
      border: solid 1px var(--neutralLight-color);
      @media(min-width: $breakpoint-large) {
        margin-left: $m-size;
      }
      &::placeholder {
        color: var(--neutralLighter-color);
        font-weight: 300;
      }

    }
  }
}