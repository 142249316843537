
* {
  box-sizing: border-box //content-box is default
}


html {
  font-size: 62.5%; //makes 1rem 10px
  @media print {
    margin:0mm;
    padding: 0mm;
    font-size:7pt;
}
}

body {
  background: var(--basicDarker-color);
  color: var(--neutralDarker-color);
  font-family: 'Open Sans', sans-serif;
  font-size: $font-standard;
  font-weight:300;
  line-height: 1.6; //16px
  overflow:auto; //TND scroll?
  @media(min-width: $breakpoint-large) {
    overflow:hidden;
  }
  @media print {
    background: #FFF !important;
    color: #000000;
    margin-bottom: 0mm;
}
}

.pointer {
  cursor: pointer
}

.flip90 {
  transform: rotate(90deg);
}

//margins & paddings
.mtM{
  margin-top: $m-size
}
.mbM{
  margin-bottom: $m-size
}
.mlM{
  margin-left: $m-size
}
.mrM{
  margin-right: $m-size
}
.mlL {
  margin-left: $l-size
}

.ptM{
  padding-top: $m-size
}
.pbM{
  padding-bottom: $m-size
}
.plM{
  padding-left: $m-size
}
.prM{
  padding-right: $m-size
}

.ptXS{
  padding-top: $xs-size
}
.pbXS{
  padding-bottom: $xs-size
}
.plXS{
  padding-left: $xs-size
}
.prXS{
  padding-right: $xs-size
}

/*LAYOUT*/
.flexRow {
  display:flex;
  align-items: center;
}